export const servicesSlider = (Swiper)=> {
    const sliderItems = document.querySelectorAll('.services--item--image-wrapper');

    let interval;

    sliderItems?.forEach(item=> {
        const itemSwiper = item.querySelector('.services-swiper')
        const itemSwiperPagination = item.querySelector('.services-swiper-pagination')
        const itemSwiperPrev = item.querySelector('.services-swiper-prev')
        const itemSwiperNext = item.querySelector('.services-swiper-next')

        const swiper = new Swiper(itemSwiper, {
            loop: true,
            spaceBetween: 20,
            pagination: {
                el: itemSwiperPagination,
            },
            navigation: {
                nextEl: itemSwiperNext,
                prevEl: itemSwiperPrev,
            },
        });



        // let currentIndex = 0;
        // const images = item.querySelectorAll('.services--item--image');
        //
        // interval = setInterval(()=> {
        //     images?.forEach(image=> {
        //         image.classList.remove('active');
        //     })
        //     if (images[currentIndex]) {
        //         images[currentIndex].classList.add('active');
        //         currentIndex++
        //     } else {
        //         images[0].classList.add('active');
        //         currentIndex = 1;
        //     }
        // }, 3000);
    })
}
