export const settingTabs = () => {
  const tabs = document.querySelectorAll(".my-account-settings--tab button");
  const contentItems = document.querySelectorAll(".my-account-settings--content--item");
  if (tabs.length > 0 && contentItems.length > 0) {
    tabs.forEach((tab, index) => {
      tab.addEventListener("click", () => {
        tabs.forEach((button) => button.classList.remove("active"));
        tab.classList.add("active");
        contentItems.forEach((item) => (item.style.display = "none"));
        contentItems[index].style.display = "block";
      });
    });
  }
};
