import AOS from "aos";

export const preloader = () => {
  const block = document.querySelector("#preloader");
  const cookies = document.querySelector(".cky-consent-container");
  if (block) {
    setTimeout(() => {
      block.classList.add("show-logo");
    }, 500);

    setTimeout(() => {
      block.classList.add("hide");
      setTimeout(() => {
        block.remove();
        if (cookies) cookies.style.opacity = 1;
      }, 3000);

      setTimeout(() => {
        AOS.init({
          duration: 450,
          once: true,
          // offset: window.innerHeight / 30,
        });
      }, 700);
    }, 2000);
  } else {
    AOS.init({
      duration: 450,
      once: true,
      // offset: window.innerHeight / 30,
    });
  }

  let documentHeight = document.body.scrollHeight;
  const isDocumentChangeSize = () => {
    if (documentHeight < document.body.scrollHeight) {
      AOS.refresh();
      documentHeight = document.body.scrollHeight;
    }
  };
  isDocumentChangeSize();

  window.addEventListener("scroll", () => {
    isDocumentChangeSize();
  });
};
