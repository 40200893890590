export const upcomingEvents = (Swiper) => {
  const sections = document.querySelectorAll(".upcoming-events");

  if (sections.length > 0) {
    sections.forEach((section) => {
      const sliderMain = section.querySelector(".upcoming-events--slider");
      const items = sliderMain.querySelectorAll(".swiper-slide");
      if (sliderMain) {
        const isMobile = window.matchMedia("(max-width: 767px)").matches;
        isMobile ? section.querySelector(".swiper-navigation.laptop").remove() : section.querySelector(".swiper-navigation.mobile").remove();
        const commonConfig = {
          slidesPerView: 1.25,
          speed: 500,
          spaceBetween: 0,
          loop: items.length > 5 ? true : false,
          navigation: {
            nextEl: isMobile ? section.querySelector(".swiper-button-next.mobile") : section.querySelector(".swiper-button-next.laptop"),
            prevEl: isMobile ? section.querySelector(".swiper-button-prev.mobile") : section.querySelector(".swiper-button-prev.laptop"),
          },
          autoplay: {
            delay: 3000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          },
          breakpoints: {
            768: {
              spaceBetween: 12,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 12,
            },
          },
        };
        const swiper = new Swiper(sliderMain, commonConfig);
      }
    });
  }
};
