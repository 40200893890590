export const reviews = (Swiper) => {
  const sections = document.querySelectorAll(".reviews");

  if (sections.length > 0) {
    sections.forEach((section) => {
      const sliderMain = section.querySelector(".reviews--slider");
      const items = sliderMain.querySelectorAll(".swiper-slide");
      const nav = section.querySelector(".reviews--nav");
      if (items.length === 1 && nav) {
        nav.remove();
      }
      if (sliderMain) {
        const commonConfig = {
          slidesPerView: 1,
          speed: 500,
          spaceBetween: 0,
          loop: true,
          effect: "fade",
          fadeEffect: {
            crossFade: true,
          },
          navigation: {
            nextEl: section.querySelector(".swiper-button-next"),
            prevEl: section.querySelector(".swiper-button-prev"),
          },
          autoplay: {
            delay: 3000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          },
        };
        const swiper = new Swiper(sliderMain, commonConfig);
      }
    });
  }
};
