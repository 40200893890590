import { vars } from "../../vars.js";

export const burger = () => {
  const header = vars.header;

  document.addEventListener("click", (e) => {
    const burgerBtn = e.target.closest(".header--mobile-burger");
    const mobileMenu = header.querySelector(".header--mob-menu");

    if (burgerBtn && mobileMenu) {
      mobileMenu.style.height = `${window.innerHeight - header.offsetHeight}px`;
      burgerBtn.classList.toggle("active");
      header.classList.toggle("active");
      $(mobileMenu).fadeToggle(300);

      document.documentElement.classList.toggle("overflow-hidden");
      document.body.classList.toggle("overflow-hidden");
    }
  });

  if (header) {
    window.addEventListener("resize", () => {
      const mobileMenu = header.querySelector(".header--mob-menu");
      if (!navigator.userAgent.match(/iPhone|iPad|iPod/i) && header.classList.contains("active") && mobileMenu) {
        mobileMenu.style.height = `${window.innerHeight - header.offsetHeight}px`;
      }
    });

    window.addEventListener("orientationchange", () => {
      const mobileMenu = header.querySelector(".header--mob-menu");
      if (header.classList.contains("active") && mobileMenu) {
        mobileMenu.style.height = `${window.innerHeight - header.offsetHeight}px`;
      }
    });
  }
};
