export const soonItems = () => {
  const headerSoonMenuItems = document.querySelectorAll(".soon a");
  if (headerSoonMenuItems.length > 0) {
    headerSoonMenuItems.forEach((element) => {
      const wrapperSpan = document.createElement("span");
      wrapperSpan.classList.add("wrapper-span");

      while (element.firstChild) {
        wrapperSpan.appendChild(element.firstChild);
      }

      const soonSpan = document.createElement("span");
      soonSpan.classList.add("soon");
      soonSpan.textContent = "Soon";

      element.appendChild(wrapperSpan);
      element.appendChild(soonSpan);
    });
  }
};
