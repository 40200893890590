export default function ShowPassword() {
  const inputs = document.querySelectorAll("input[type='password']");

  inputs.forEach((input) => {
    const closest = input.closest("span");

    if (!closest) return;

    closest.style.position = "relative";

    const toggleButton = document.createElement("div");
    toggleButton.classList.add("show-password");
    closest.appendChild(toggleButton);

    toggleButton.addEventListener("click", () => {
      const isPasswordVisible = input.type === "text";
      input.type = isPasswordVisible ? "password" : "text";

      toggleButton.classList.toggle("open", !isPasswordVisible);
      input.classList.toggle("open", !isPasswordVisible);
    });
  });
}
