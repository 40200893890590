import { headerFixed } from "./modules/header/header-fixed.js";
import { burger } from "./modules/header/burger-menu.js";
import { accountDropdown } from "./modules/header/account-dropdown.js";
import { soonItems } from "./modules/header/soon-items.js";
import { singlePostTypeDetection } from "./modules/header/single-post-type-detection.js";
import { sections } from "./modules/sections/sections.js";
import { wpcf7 } from "./modules/wpcf7.js";
import { anchorLink } from "./modules/anchor-link.js";
import { preloader } from "./modules/preloader.js";
import { myAccount } from "./modules/my-account/my-account.js";
import { fullHeightSection } from "./modules/full-height-section.js";
import ShowPassword from "./modules/my-account/parts/show-password.js";
import { popup } from "./modules/popup.js";
import copy from "./modules/copy-to-clickboard.js";

document.addEventListener("DOMContentLoaded", function (event) {
  headerFixed();
  preloader();
  soonItems();
  singlePostTypeDetection()
  fullHeightSection();
  ShowPassword();
  popup();
  let scriptLaunchStart = true;
  const scriptLaunch = () => {
    burger();
    accountDropdown();
    myAccount();
    sections();
    wpcf7();
    anchorLink();
    copy();
  };
  if (window.pageYOffset >= 1 && scriptLaunchStart) {
    scriptLaunchStart = false;
    scriptLaunch();
  } else if (window.pageYOffset < 1 && scriptLaunchStart) {
    const events = ["mousemove", "click", "mousewheel", "scroll", "touchstart", "keydown", "keypress", "mouseover", "mouseout", "resize"];
    events.forEach((event) => {
      window.addEventListener(event, () => {
        if (scriptLaunchStart) {
          scriptLaunchStart = false;
          scriptLaunch();
        }
      });
    });
  }
});
