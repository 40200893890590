import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, Keyboard, Controller, EffectFade } from "swiper/modules";

import { upcomingEvents } from "./parts/upcoming-events.js";
import { reviews } from "./parts/reviews.js";
import { faq } from "./parts/faq.js";
import { AboutUsFirstScreen } from "./parts/about-us-first-screen.js";
import { related } from "./parts/related.js";
import { privateTrainingVideo } from "./parts/privateTrainingVideo.js";

export const sections = () => {
  Swiper.use([Autoplay, Navigation, Pagination, Keyboard, Controller, EffectFade]);

  upcomingEvents(Swiper);
  reviews(Swiper);
  related(Swiper);

  faq();
  AboutUsFirstScreen();
  privateTrainingVideo();
};
