export const privateTrainingVideo = () => {
  const sections = document.querySelectorAll(".private-training-single--preview");

  if (sections.length > 0) {
    sections.forEach((section) => {
      const video = section.querySelector("video");
      const timer = section.querySelector(".course--preview--video--timer");

      if (video) {
        const savedTime = localStorage.getItem(video.id + "_time");
        if (savedTime) {
          video.currentTime = parseFloat(savedTime);
        }
        video.addEventListener("timeupdate", () => {
          localStorage.setItem(video.id + "_time", video.currentTime);
        });
        video.addEventListener("loadedmetadata", () => {
          const savedTime = localStorage.getItem(video.id + "_time");
          if (savedTime) {
            video.currentTime = parseFloat(savedTime);
          }
        });

        section.addEventListener("click", () => {
          if (!timer) {
            section.classList.add("show-video");
            video.play();
          }
        });
      }
    });
  }
};
