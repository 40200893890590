export const servicesSlider = ()=> {
    const sliderItems = document.querySelectorAll('.services--item--image-wrapper');

    let interval;

    sliderItems?.forEach(item=> {
        let currentIndex = 0;
        const images = item.querySelectorAll('.services--item--image');

        interval = setInterval(()=> {
            images?.forEach(image=> {
                image.classList.remove('active');
            })
            if (images[currentIndex]) {
                images[currentIndex].classList.add('active');
                currentIndex++
            } else {
                images[0].classList.add('active');
                currentIndex = 1;
            }
        }, 3000);
    })
}
