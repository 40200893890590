import { vars } from "../vars.js";

export const fullHeightSection = () => {
  const sections = document.querySelectorAll(".error-section--content, .login-section--content");
  if (sections.length > 0) {
    const appHeight = () => {
      sections.forEach((section) => {
        if (window.matchMedia("(max-width: 767px)").matches) {
          section.style.setProperty("--app-height", `${window.innerHeight - vars.header.offsetHeight - 16}px`);
        } else {
          section.style.height = "";
        }
      });
    };
    window.addEventListener("resize", appHeight);
    appHeight();
  }
};
