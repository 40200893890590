export const anchorLink = () => {
  const body = $("body, html");
  const header = document.querySelector("#header");

  const scrollToAnchor = (id) => {
    if ($(id).length > 0) {
      let top = $(id).offset().top;
      if (header) {
        top = $(id).offset().top - header.offsetHeight - 1;
      }
      if (window.matchMedia("(min-width: 768px)").matches && window.matchMedia("(min-height: 450px)").matches) {
        window.scrollTo({
          top: top,
          behavior: "smooth",
        });
      } else {
        body.stop().animate({ scrollTop: top }, 1000);
      }
    }
  };

  if ($("a").length > 0) {
    $("a").each(function () {
      if ($(this).attr("href") && $(this).attr("href").indexOf("#") >= 0) {
        let firstIndex = $(this).attr("href").indexOf("#");
        let lastIndex = $(this).attr("href").length;
        let id = $(this).attr("href").substring(firstIndex, lastIndex);
        if (id !== "#" && id !== "##" && $(this).attr("href").indexOf("#/") < 0 && $(id).length > 0) {
          $(this).on("click", function (e) {
            e.preventDefault();
            scrollToAnchor(id);
          });
        }
      }
    });
  }

  const checkAnchorInUrl = () => {
    const url = window.location.href;
    const index = url.indexOf("#");
    if (index !== -1) {
      const idInUrl = url.substring(index);
      scrollToAnchor(idInUrl);
      history.replaceState(null, null, url.substring(0, index));
    }
  };
  checkAnchorInUrl();
};
