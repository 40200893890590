export const wpcf7 = () => {
  const inputs = document.querySelectorAll("input");

  if (inputs.length > 0) {
    const checkValid = () => {
      inputs.forEach((input) => {
        const closest = input.closest(".wpcf7-form-control-wrap");
        if (closest && input.classList.contains("wpcf7-not-valid")) {
          closest.classList.add("not-valid");
        }
      });
    };
    checkValid();

    const reset = () => {
      inputs.forEach((input) => {
        const closest = input.closest(".wpcf7-form-control-wrap");
        if (input.closest(".wpcf7-form-control-wrap")) {
          closest.classList.remove("not-valid");
        }
      });
    };

    document.addEventListener(
      "wpcf7invalid",
      (event) => {
        setTimeout(() => {
          checkValid();
        }, 300);
      },
      false
    );
    document.addEventListener(
      "wpcf7submit",
      (event) => {
        reset();
      },
      false
    );

    const forms = document.querySelectorAll("form.wpcf7-form");
    if (forms.length > 0) {
      forms.forEach((form) => {
        const closest = form.closest(".cf7");
        if (closest) {
          form.addEventListener(
            "wpcf7mailsent",
            function (event) {
              closest.classList.add("thanks-js");
              setTimeout(() => {
                closest.classList.remove("thanks-js");
              }, 15000);
            },
            false
          );
        }
      });
    }

    const hideClosestWrap = () => {
      const hiddenInputs = document.querySelectorAll('input[type="hidden"]');
      hiddenInputs.forEach((input) => {
        const wrap = input.closest(".wpcf7-form-control-wrap");
        if (wrap) {
          wrap.style.display = "none";
        }
      });
    };
    hideClosestWrap();

    const submitButtons = document.querySelectorAll('button[type="submit"], input[type="submit"]');
    if (submitButtons.length > 0) {
      submitButtons.forEach((button) => {
        button.addEventListener("click", (event) => {
          button.style.pointerEvents = "none";
          button.style.opacity = "0.6";
          setTimeout(() => {
            button.style.pointerEvents = "auto";
            button.style.opacity = "";
          }, 2500);
        });
      });
    }
  }
};
