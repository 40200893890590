export const AboutUsFirstScreen = () => {
  const header = document.querySelector("#header");
  const blocks = document.querySelectorAll(".sticky-block");
  if (blocks.length > 0) {
    blocks.forEach((block) => {
      const wrapper = block.closest(".sticky-wrapper");
      if (wrapper) {
        const reset = (block) => {
          block.classList.remove("fixed");
          block.classList.remove("absolute");
          block.style.top = "";
          block.style.bottom = "";
          block.style.maxWidth = "";
          block.style.transition = "";
        };
        const func = () => {
          let blockHeight = block.offsetHeight;
          let wrapperHeight = wrapper.offsetHeight;
          let wrapperStyles = window.getComputedStyle(wrapper);
          let wrapperPT = parseFloat(wrapperStyles.getPropertyValue("padding-top"));
          let wrapperPB = parseFloat(wrapperStyles.getPropertyValue("padding-bottom"));

          if (window.matchMedia("(min-width: 768px)").matches && blockHeight < wrapperHeight - wrapperPT - wrapperPB && blockHeight < window.innerHeight - wrapperPT - wrapperPB) {
            // if (window.matchMedia("(min-width: 768px)").matches && blockHeight < wrapperHeight - wrapperPT - wrapperPB) {
            wrapperHeight = wrapper.offsetHeight;
            wrapperStyles = window.getComputedStyle(wrapper);
            wrapperPT = parseFloat(wrapperStyles.getPropertyValue("padding-top"));
            wrapperPB = parseFloat(wrapperStyles.getPropertyValue("padding-bottom"));
            const wrapperPR = parseFloat(wrapperStyles.getPropertyValue("padding-right"));
            const wrapperPL = parseFloat(wrapperStyles.getPropertyValue("padding-left"));

            let offsetTop = wrapperPT;
            let hOffset = 0;

            if (header) {
              if (header.classList.contains("hide")) {
                offsetTop = wrapperPT + 40;
                hOffset = 0;
              } else {
                offsetTop = header.offsetHeight + wrapperPT + 10;
                hOffset = header.offsetHeight;
              }
            }

            blockHeight = block.offsetHeight;

            const rect = wrapper.getBoundingClientRect();
            const topPosition = rect.top - hOffset;
            const bottomPosition = topPosition + wrapperHeight - blockHeight - wrapperPB - offsetTop + hOffset;

            if (topPosition <= 0 && bottomPosition > 0) {
              block.classList.remove("absolute");
              block.classList.add("fixed");
              block.style.bottom = "auto";
              block.style.top = `${offsetTop}px`;
              block.style.transition = "top .6s easy-in-out, bottom .6s easy-in-out";
              block.style.maxWidth = `${wrapper.offsetWidth - wrapperPR - wrapperPL}px`;
            } else if (topPosition <= 0 && bottomPosition <= 0) {
              block.classList.remove("fixed");
              block.classList.add("absolute");
              block.style.top = "auto";
              block.style.bottom = `${wrapperPB}px`;
              block.style.transition = "top .6s easy-in-out, bottom .6s easy-in-out";
              block.style.maxWidth = `${wrapper.offsetWidth - wrapperPR - wrapperPL}px`;
            } else {
              reset(block);
            }
          } else {
            reset(block);
          }
        };
        func();
        window.addEventListener("scroll", () => {
          func();
        });
        window.addEventListener("orientationchange", () => {
          func();
        });
        window.addEventListener("resize", () => {
          if (!navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            func();
          }
        });
      }
    });
  }
};
