import { vars } from "../../vars.js";

export const headerFixed = () => {
  const header = vars.header;
  if (header) {
    const homeHero = document.querySelector(".hs-type-1");
    const main = document.querySelector("main");

    let isDesktop = window.innerWidth >= 1025;
    let isSpecial = !!homeHero;

    const func = () => {
      let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      isDesktop = window.innerWidth >= 1025; // Update isDesktop on each call
      if (isSpecial && isDesktop) {
        const heroHeight = homeHero.offsetHeight;
        homeHero.style.marginBottom = `${header.offsetHeight}px`;
        header.classList.add("special");
        header.style.top = `${heroHeight}px`;
        main.style.paddingTop = `${0}px`;
        if (currentScroll >= heroHeight) {
          header.style.position = "fixed";
          header.style.top = "0";
        } else {
          header.style.position = "absolute";
          header.style.top = `${heroHeight}px`;
        }
      } else {
        header.style.top = `${0}px`;
        header.style.position = "fixed"; // Ensure header is fixed in all other cases
        main.style.paddingTop = `${header.offsetHeight}px`;
      }
    };

    func();

    window.addEventListener("orientationchange", func);
    window.addEventListener("resize", func);
    window.addEventListener("scroll", func);
  }
};
