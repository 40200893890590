export const faq = () => {
  $(".faq--item").each(function () {
    $(this).on("click", function () {
      var $faqItem = $(this);
      if ($faqItem.hasClass("active")) {
        $faqItem.find(".faq--item--answer").slideUp(250);
        $faqItem.removeClass("active");
        return;
      }
      $faqItem.siblings(".faq--item").removeClass("active").find(".faq--item--answer").slideUp(440);
      $faqItem.find(".faq--item--answer").slideToggle(400);
      $faqItem.toggleClass("active");
    });
  });
};
