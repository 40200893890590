export const singlePostTypeDetection = ()=> {
    const mainWrapper = document.querySelector('main');
    const headerMenuItems = document.querySelectorAll('.header--menu>ul>li');
    if (mainWrapper) {
        if (mainWrapper.hasAttribute('data-post-type')) {
            headerMenuItems?.forEach(item=> {
                if (item.classList.contains(`menu-item-object-${mainWrapper.getAttribute('data-post-type')}`)) {
                    item.classList.add('current-menu-item');
                }
            })
        }
    }
}