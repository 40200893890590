export default function copy() {
  const copyIcons = document.querySelectorAll(".copy-js");

  copyIcons.forEach((icon) => {
    icon.addEventListener("click", (event) => {
      const textElement = event.currentTarget.closest(".copy-wrapper-js").querySelector(".copy-text-js");
      const dataPath = textElement.getAttribute("data-copy");
      if (textElement && dataPath) {
        navigator.clipboard
          .writeText(textElement.textContent.trim())
          .then(() => {
            icon.classList.add("active");
            setTimeout(() => {
              icon.classList.remove("active");
            }, 5000);
          })
          .catch((err) => {
            console.error("Failed to copy text: ", err);
          });
      } else {
        console.error("Text element not found.");
      }
    });
  });
}
