import { vars } from "../../vars.js";

export const accountDropdown = () => {
  const header = vars.header;
  const menuItem = header.querySelector(".header--account");

  if (menuItem) {
    let timeout;
    const dropDownItem = menuItem.querySelector(".header--account--dropdown");
    const arrowToggle = menuItem.querySelector(".header--account--name");
    const openDropdown = () => {
      clearTimeout(timeout);
      dropDownItem.classList.add("active");
      arrowToggle.classList.add("active");
    };
    menuItem.addEventListener("mouseenter", openDropdown);
    menuItem.addEventListener("mouseleave", () => {
      timeout = setTimeout(() => {
        if (!menuItem.matches(":hover") && !dropDownItem.matches(":hover")) {
          dropDownItem.classList.remove("active");
          arrowToggle.classList.remove("active");
        }
      }, 300);
    });

    dropDownItem.addEventListener("mouseenter", openDropdown);
    dropDownItem.addEventListener("mouseleave", () => {
      timeout = setTimeout(() => {
        if (!menuItem.matches(":hover") && !dropDownItem.matches(":hover")) {
          dropDownItem.classList.remove("active");
          arrowToggle.classList.remove("active");
        }
      }, 300);
    });
  }
};
