export const popup = () => {
  const popupsAndButtonsMatch = {
    "account-sign-up": "btn-account-sign-up",
    "thanks-popup": "btn-thanks-popup",
    "after-videos": "btn-after-videos",
  };
  const popups = document.querySelectorAll(".popup");
  if (popups.length > 0) {
    const closePopup = (popup, redirectURl = false) => {
      if (redirectURl && redirectURl.length > 0) {
        window.location.href = redirectURl;
      }
      if (!popup.classList.contains("opened")) {
        popup.classList.remove("active");
        popup.classList.add("not-active");

        document.documentElement.classList.remove("overflow-hidden");
        document.body.classList.remove("overflow-hidden");
      }
    };

    const showPopup = (popup) => {
      document.documentElement.classList.add("overflow-hidden");
      document.body.classList.add("overflow-hidden");

      popup.classList.remove("not-active");
      popup.classList.add("active");
    };

    popups.forEach((popup) => {
      const popupId = popup.id;

      popup.addEventListener("click", (e) => {
        if (e.target.closest(".popup--close") || !e.target.closest(".popup--form")) {
          closePopup(popup);
        }
      });

      document.addEventListener("keydown", (event) => {
        if (!popup.classList.contains("opened")) {
          if (event.key === "Escape") {
            closePopup(popup);
          }
        }
      });

      if (popupsAndButtonsMatch[popupId]) {
        const btnShow = document.querySelectorAll(`.${popupsAndButtonsMatch[popupId]}`);
        if (btnShow.length > 0) {
          btnShow.forEach((btn) => {
            btn.addEventListener("click", (e) => {
              e.preventDefault();
              showPopup(popup);
            });
          });
        }
      } else {
        if (popupId) {
          const newButtons = document.querySelectorAll(`.${popupId}`);
          if (newButtons.length > 0) {
            newButtons.forEach((btn) => {
              btn.addEventListener("click", (e) => {
                e.preventDefault();
                showPopup(popup);
              });
            });
          }
        }
      }
    });
  }
};
